:root {
  --background-color: #202020;
  --mainBackgroundColor: "#BCAC80";
  --navBarColor: "light";
  --mainTextColor: "#000";
  --chatBackgroundColor: "#D0C4A0";
  --chatTextColor: "#000";
  --userListBackgroundColor: "#D0C4A0";
  --userListTextColor: "#000";
  --sysMessageColor: "blue";
  --featureMessageColor: "darkgray";
  --enterColor: "green";
  --leaveColor: "chocolate";
  --chatFromGirlColor: "crimson";
  --chatFromUserColor1: "darkolivegreen";
  --chatFromUserColor2: "darkred";
  --chatFromUserColor3: "darkmagenta";
  --featureRequestColor: "indigo";
  --featureAcceptedColor: "mediumseagreen";
  --featureDeniedColor: "darkred",
}


.modal-dialog {
  max-height: 100vh;
  margin-left: 0 !important;

}

.modal-content {
  overflow: hidden;
  min-width: 900px;

}

.modal-title {
  color: white
}


.react-draggable .modal-content {
  margin-top: -40px !important;
}

#MessengerConversationDIV {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 480px);
}


.overflow {

  overflow-y: auto;
  overflow-x: hidden;
}

.paginationundermessage {
  position: sticky;
  bottom: 180px;
  z-index: 2;
}

.messengerUser {
  cursor: pointer;
}

#MessengerConversationsPane {
  width: 290px;
}

#MessengerMessagesPane {
  position: relative;
  width: calc(100% - 300px);
}

.videoItem {
  width: calc(50% - 8px);
}

@media only screen and (max-width: 900px) {
  .videoItem {
    width: calc(100% - 8px);
  }

  .modal-content {
    overflow: hidden;
    min-width: 100vw;

  }
}

.imageItem {
  width: calc(25% - 8px);

}

.imageItem img {
  height: 100px;
}

@media only screen and (max-width: 900px) {
  .imageItem {
    width: calc(50% - 8px);
  }
}

.showLivecamCheckbox {
  margin-right: 5px;
  height: 20px;
  width: 20px;
  margin-top: 0px;
}

.salesList {
  cursor: pointer;
}

.salesRecordItem {
  cursor: pointer;
}

.userList {
  cursor: pointer;
}

.userRecordItem {
  cursor: pointer;
}

.BelowPostButton {
  position: relative;
  margin: auto;
}

.BelowPostButtonPayIcon {
  position: absolute;
  bottom: 0px;
  right: 2px;
  font-size: 11px;
}

.MessengerExtraButton {
  font-size: 12px;
  padding: 2px 5px;
}

.smallMediaPreview {
  width: 80px;
  position: relative;
}


.smallMediaPreviewVideo {
  width: 165px;
  position: relative;
}

.smallMediaPreviewIcon {
  width: 100%;
  padding: 2px 3px 1px 3px;
  color: #555;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 13px;
}

.subscriptionTier {
  cursor: pointer;
}

#VideoListHeader {
  margin-top: 20px;
}

#VideoListUpload {
  margin-top: 20px;
  margin-bottom: 20px;
}

#SingleVideoPane {
  margin-top: 20px;
}

.videoListItem {
  cursor: pointer;
}

.videoThumbItem {
  cursor: pointer;
}

#root {
  height: 100%;
}

.maincontainer {
  height: 100%;
}

.reviewed {
  border: solid 2px green;
}

.inreview {
  border: solid 2px lightgray;
}

.smallMediaPreviewReview {
  position: absolute;
  left: 1px;
  bottom: 1px;
  z-index: 2;
}

.modeladvertorial_pad {
  padding: 10px;
}

.userinfo a {
  text-decoration: none;
}

.userinfodm {
  z-index: 0;
  background-color: rgb(255, 255, 255, 0.3);
  border: 1px solid rgb(255, 255, 255, 0.5);
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  color: #000;
  color: aliceblue;
}

.hoverme:hover {
  background-color: #DC9A9D;

  box-shadow: 3px 3px 5px 2px rgb(237, 237, 237, 0.6);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2.8s;
}


.userinfodm a {
  text-decoration: none;
}

.modeladvertorial_userinfo {
  z-index: 0;
  background-color: #fff;
  border: 1px solid #eeeeee;
  width: 100%;
  border-radius: 10px;
  color: #000;
  transition: 0.8s;
  height: auto;
}

.modeladvertorial_userinfoheader {
  position: relative;
}

.modeladvertorial_user img {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  border: 4px solid #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.modeladvertorial_hideiftoobig {
  text-overflow: ellipsis;
  overflow: hidden;
}

.modeladvertorial_userinfoheader img {
  width: 100%;
  min-height: 50%;
  height: 100%;
  max-width: 790px;
  max-height: 400px;
  object-fit: cover;
  object-position: top left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modeladvertorial_userinfoheadermobile img {
  height: 100%;

  max-width: 100%;
  object-fit: cover;
}

.modeladvertorial_userinfoheadershort img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  transform-origin: top right;
}

.modeladvertorial_userinfo>h4>a {
  font-size: 1.4rem;
  font-weight: 700;
}

.modeladvertorial_jahre {
  color: #000;
}

.modeladvertorial_makehigher {
  margin-top: -10px;
}

.modeladvertorial_desctextshort {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.modeladvertorial_userinfomobile {
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 10px;
}

.modeladvertorial_userinfobodymobile a {
  transition: 0.5s;
}

.modeladvertorial_userinfobodymobile a:hover {
  color: #000;
}

.modeladvertorial_userinfobodymobile h4 {
  font-size: 1.4rem;
  font-weight: bold;
}

.modeladvertorial_userinfobodymobile h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

.modeladvertorial_userinfobodymobile p {
  font-size: 14px;
  margin: 8py 0;
}


.modeladvertorial_user {
  display: flex;
  margin-top: auto;
  padding: 20px;
  position: absolute;
  z-index: 2;
}

.modeladvertorial_wrapthings {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
}

.grayscale {
  filter: grayscale(1);
}

.modal p {
  color: white;
}

.modeladvertorial_tag {
  margin: 0 0 10px 0;
}

.modeladvertorial_tag span {
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  margin-right: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.modeladvertorial_tagmobile {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

.modeladvertorial_plus {
  font-size: 26px !important;
  line-height: 16px;
  font-weight: bold !important;
  color: #000 !important;
  display: inline-block;
  cursor: pointer;
}

.modeladvertorial_tagmobile span {
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.modeladvertorial_tagplusmobile {
  text-decoration: none;
  color: #04003b;
  padding: 5px 7px;
  line-height: 0.7;
  font-weight: bolder;
  border-radius: 20px;
  margin-right: 10px;
  text-align: center;
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
  border: 2px solid #04003b;
}

.modeladvertorial_tagfavicon {
  width: 30px;
  cursor: pointer;
  margin: 1%;
}

.modeladvertorial_tagfavicon :hover {
  width: 40px;
  transition: 0.8s;
}

.modeladvertorial_tagbtnout {
  margin: 10px auto;
}

.modeladvertorial_tagbtn {
  z-index: 0;
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 5px;
  margin: 0 auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.10);
  border-radius: 5px;
  transition: 0.5s;
}

.modeladvertorial_tagbtn span {
  position: relative;
  z-index: 1;
}

.modeladvertorial_tagbtn {
  color: #fff;
  text-decoration: none
}

.modeladvertorial_tagbtn:hover {
  background-color: #04003b;
}

.modeladvertorial_tagsetback {
  z-index: -40;
}

.modeladvertorial_tagmobileName {
  padding: 3%;
  color: black;
  text-decoration: none;
}

.modeladvertorial_tagmobileNameLink {
  color: black;
}

.modeladvertorial_tagmobileNameLink a {
  color: black;
  text-decoration: none;
}

.modeladvertorial_tagplus {
  display: inline;
  text-decoration: none;
  color: #04003b;
  padding: 0px 12px;
  line-height: 0.7;
  font-weight: bolder;
  border-radius: 20px;
  margin-right: 10px;
  text-align: center;
  margin-top: 15px;
  width: 30%;
  cursor: pointer;
}

.modeladvertorial_tagplus:hover {
  background-color: #2e2e40;
  color: #fff !important;
}

.modeladvertorial_tagheart {
  z-index: 100;
  position: absolute;
  animation: heartfade 2s linear;
}

.modeladvertorial_tagheart:before,
.modeladvertorial_tagheart:after {
  margin-top: -30px;
  content: "";
  z-index: 100;
  position: absolute;
  height: 22px;
  width: 35px;
  border-radius: 15px 0px 0px 15px;
}

.modeladvertorial_tagheart:before {
  transform: rotate(45deg);
}

.modeladvertorial_tagheart:after {
  left: 10.5px;
  transform: rotate(135deg);
}

@keyframes heartfade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(-30px);
  }

  70% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 0;
  }
}

.modeladvertorial_tagthankyoutext {
  color: black;
}

.modeladvertorial_tagthankyoudiv {
  width: 50vw;
  height: 100%;
  padding: 5%;
}

.modeladvertorial_tagthankyouimage {
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  margin-right: 5%;
}

.modeladvertorial_tagthankyouheader {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.modeladvertorial_tagthankyouimagetext {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (max-width: 1068px) {
  .modeladvertorial_tagthankyoudiv {
    width: 75vw;
  }

  .modeladvertorial_tagthankyouimage {
    width: 30vw;
    height: 30vw;
  }
}

.modeladvertorial_userinfobody {
  width: 100%;
  z-index: 1;
  background-color: #fff;
  padding: 10px 20px;
}

.modeladvertorial_userinfobody a {
  transition: 0.5s;
}

.modeladvertorial_userinfobody a:hover {
  color: #000;
}

.modeladvertorial_userinfobody h4 {
  font-size: 1.4rem;
  font-weight: bold;
}

.modeladvertorial_userinfobody h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

.modeladvertorial_userinfobody p {
  font-size: 14px;
  margin: 8py 0;
}


#IDShotsListDIV {
  margin-top: 20px;
}

.idshotFileItem {
  width: 33%;
}

.idshotImageFile {
  width: 100%;
}

.idshotPDFFile {
  width: 100%;
  text-align: center;
}

.idshotItem {
  width: calc(100% - 8px);
  border: solid 1px #777;
  background-color: #eee;
  padding: 8px;
  border-radius: 4px;
}

.idshotItemTable {
  width: 100%;
}

.idshotItemTable td {
  border-top: 1px solid #aaa;
}

#msgheadlineright {
  display: none;
}

.editText {
  color: rgb(66, 66, 66, 0.6);
}

#MessengerMessagesPane {
  padding-left: 10px;
}

.timelinePostPriceLabel {
  background-color: #555;
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 11px;
  margin-right: 5px;
}

.alert-mergepossible {
  margin-bottom: 0px !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-end-start-radius: 0px !important;
  border-end-end-radius: 0px !important;
}

.post-mergepossible {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 5px;
}

.timelinePostToMerge {
  opacity: 0.5;
  border: solid 1px #777;
  background-color: #eee;
  padding: 8px;
  border-radius: 4px;
  border-start-start-radius: 0px !important;
  border-start-end-radius: 0px !important;
  margin-top: 0px !important;
}

.timelinePostToMerge>.timelinePostHeader {
  background-color: #ccc;
}

.timelinePostDeleted>.timelinePostHeader {
  background-color: peachpuff;
  text-decoration: line-through;
}

.timelinePost {
  margin-bottom: 10px;
  ;
}

.timelinePostHeaderApproved {
  background-color: #E3FDD8;
}

.timelinePostHeaderNotApproved {
  background-color: #FDD8D8;
}

.freeMediaContainerNewTimelinePost {
  margin-top: 10px;
  padding: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  border: solid 1px #777;
}

.payMediaContainerNewTimelinePost {
  margin-top: 10px;
  padding: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  border: solid 1px #777;
}

.freeMediaContainerExistingTimelinePost {
  margin-top: 10px;
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  border: solid 1px #777;
}

.payMediaContainerExistingTimelinePost {
  margin-top: 10px;
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  border: solid 1px #777;
}

.PostHeadlineLabels {
  height: 25px;
  width: 100%;
  display: block;
}

@media (min-width: 740px) {
  .PostHeadlineLabels {
    height: 25px;
    width: 100%;
    display: block;
  }
}

.post-approved {
  background-color: green;
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 4px;
}

.post-pinned {
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 4px;
}

.post-promo {
  background-color: darkcyan;
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 4px;
}

.post-nonsub {
  background-color: grey;
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 4px;
}

.post-onlysub {
  background-color: grey;
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 4px;
}

.post-deleted {
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  text-decoration: line-through;
  border-radius: 3px;
  margin-left: 4px;
}

.post-rejected {
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 4px;
}

.post-inreview {
  background-color: darkgrey;
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 4px;
}

.post-reviewcomment {
  background-color: darkgrey;
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 4px;
}

@media (max-width: 740px) {
  .msgselected {
    display: none;
  }

  .nomsgselected {
    display: block;
  }

  #MessengerMessagesPane {
    width: 100%;
  }

  #msgheadlineright {
    display: block;
  }

  #UserListsPane {
    width: 100%;
  }

  .userlistselected {
    display: none;
  }

  .nouserlistselected {
    display: block;
  }
}

#SubscriptionTiersDIV {
  width: 100%;
}

.modal .btn-primary {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 8px;

  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.10);
  border-radius: 5px;
  transition: 0.5s;
}

.modal .alert-info {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */
  color: #fff;
}

.close {
  border-radius: 5px;
  font-weight: bold;
}

.pageHeadline {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 400;
  border-radius: 4px;
}

.list-group-item.active {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a90329', endColorstr='#6d0019', GradientType=0);
  /* IE6-9 */
}

#fansLogoMobi {
  position: absolute;
  top: 5px;
  left: 0px;
  width: 48px;
}

.fansMobiInfobar {
  padding-left: 60px;
  margin-bottom: 10px;
}

.fansMobiButtonbar {
  position: absolute;
  left: 60px;
  top: 34px;
}


.importantTODOItem>a {
  text-decoration: none;
}

.imageUploadComplete {
  padding: 4px 8px;
  color: #fff;
}

.helptextTimeline {
  color: #888;
  font-size: 0.8rem;
  display: inline-block;
  margin-left: 10px;
}

.aboGroupItem {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.aboGroupItemHeadline {
  font-size: 14px;
  color: #000;
  line-height: 18px;
  height: 72px;
  margin-bottom: 10px;
}

.aboGroupItemDescription {
  font-size: 12px;
  color: #666;
  line-height: 12px;
  height: 84px;
  margin-bottom: 5px;
  text-align: center;
}

.aboGroupItemAsterisk {
  font-size: 12px;
  color: #666;
  line-height: 14px;
  height: 28px;
  margin-bottom: 0px;
}

.TextTabButton {
  background-color: #666;
  color: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 3px 8px;
  margin-right: 2px;
  font-size: 9px;
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 0px;
  cursor: pointer;
}

.TextTabButtonActive {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */

}

.TextTabIcon {
  display: inline-block;
  font-size: 12px;
  margin-right: 2px;
}

.TextTabIconEnd {
  display: inline-block;
  font-size: 12px;
  margin-left: 2px;
}

.videoItemTitle {
  padding-left: 3px;
  padding-top: 1px;
}

.videoBoxUpper {
  padding-left: 2px;
}

.videoBoxLower {
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 2px;
  white-space: nowrap;
}

.page-item:first-child {
  padding-left: 5px;
  padding-bottom: 5px;
}

.page-item {
  padding-bottom: 5px;

}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #6c757d;
  border-color: #6c757d;
}

.topRightButton {
  position: absolute;
  z-index: 100;
  top: 0px;
  right: 12px;
  padding: 1px 3px;
}

.testpostcard_maincontent {
  color: #040222;
  margin: 10px;
  padding: 10px;
  background: #fff;
  border: 1px solid rgba(34, 34, 34, .12549019607843137);
  border-radius: 10px;
  scroll-snap-align: start;
}

.testpostcard a {
  text-decoration: none;
}

.testpostcard_newcontainer {
  margin-bottom: 3px;
  width: 100%;
}

.testpostcard_new {
  text-align: right;
  padding: 5px;
  margin-right: 10px;
}

.testpostcard_maxwidth {
  width: 100%;
  padding: 5px;
}

.testpostcard_pseudoname,
.testpostcard_showpos {
  font-weight: 700;
  text-decoration: none;
}

.testpostcard_texttoright {
  text-align: right;
  color: grey;
}

.testpostcard_flexitsm {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.testpostcard_icon,
.testpostcard_icongray {
  width: 50px;
  transition: .5s;
  cursor: pointer;
}

.testpostcard_iconsm {
  height: 15px;
  margin-top: 5px;
}

.testpostcard_marker {
  font-weight: 700;
  margin-left: 4px;
}

.testpostcard_iconliste {
  display: flex;
  align-content: space-around;
  min-height: 50px;
  background: color #efefef;
  margin: 0 5px;
  border-radius: 10px;
}

.testpostcard_flexit {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.testpostcard_icongray {
  filter: grayscale(1);
}

.testpostcard_likenumber {
  position: absolute;
  z-index: 5;
  width: 50px;
  top: 3px;
  display: flex;
  font-weight: 700;
  justify-content: center;
}

.testpostcard_heading {
  font-size: 1.3em;
  font-weight: 700;
}

.testpostcard_subheading {
  font-size: 1em;
  font-weight: 500;
}

.testpostcard_element {
  overflow: hidden;
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}

.testpostcard_elementinner {
  margin-bottom: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.testpostcard_topaywrapper {
  position: relative;
  width: 100%;
}

.testpostcard_topaybg {
  margin: auto;
  -webkit-filter: grayscale(.6);
  filter: grayscale(.6);
  object-fit: cover;
  display: flex;
  justify-content: center;
}

.testpostcard_topaytext {
  position: absolute;
  text-align: center;
  top: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: bolder;
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  font-size: 1.7em;
}

.testpostcard_topaytext>h5 {
  margin-bottom: 20%;
}

.testpostcard_distancerdown {
  margin-top: 10%;
}

.redbutton_MyButton {
  display: inline-block;
  cursor: pointer;
  color: #fff;
  font-family: Arial;
  font-size: 1em;
  padding: .8% 6px;
  text-decoration: none;
  min-width: 80px;
  transition: 1s;
  margin: .2%;
}

.redbutton_MyButton:hover {
  transition: .5s;
  background-color: #d97070;
  border: 1px solid #ac5959;
  box-shadow: 0 0 4px 0 #ac5959;
}

.scrollimage_wrapper {
  margin: auto;
  overflow: hidden;
  position: relative;
  z-index: 0;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
}

.smallimageElement_myimageelement {
  border-radius: 5px;
  margin: 5px;
  overflow: hidden;
  max-width: 100%;
  justify-content: center;
}

.displaySub_extrahead {
  text-align: center;
}

.displaySub_wrapper {
  border-radius: 10px;
  max-width: 100%;
  margin: 6px 6px 12px 6px;
  padding: 0;
  transition: .5s;
  scroll-snap-align: start;
}

.displaySub_thelist,
.displaySub_thelistdm {
  list-style: none;
  width: 100%;
  border-radius: 10px;
  margin: auto;
}

.displaySub_innerWrapper {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  margin: 0;
  padding: 10px 6px;
}

.displaySub_innerWrapper p {
  margin-bottom: 2px;
}

.displaySub_header {
  width: 100%;
  border-bottom: 1px solid #000;
  text-transform: uppercase;
  font-weight: 600;
  min-height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 1.3em;
  position: relative;
}

.displaySub_innerWrapper p {
  margin-bottom: 2px;
}

.displaySub_priceline {
  margin-top: 10px;
  display: block;
  font-weight: 700;
  text-align: center;
  font-size: 1.1rem;
}

.displaySub_kuendigung {
  margin-top: 10px;
  font-size: .8em;
}

.displaySub_initialPrice {
  font-size: 1.0em;
}

.displaySub_initialPricePast {
  font-size: .8em;
}

.displaySub_catoAc {
  width: 100%;
  cursor: pointer;
  text-align: center;
  margin: auto;
  font-weight: 700;
  font-size: 1.5em;
  color: #fff;
}

.displaySub_column {
  background-color: #e9ebeb;
  padding-top: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.displaySub_containertrans {
  z-index: 0;
  background-color: initial;
  border-radius: 10px;
  overflow: hidden;
  color: #000;
  margin: 1%;
  padding: 0;
  width: 98%;
}

h5 {
  color: white;
}

#logonavbar {
  position: relative;
  display: flex;
  justify-content: center;
  /* Zentriert die Kinder horizontal */
  align-items: center;
  /* Zentriert die Kinder vertikal */
  min-height: 60px;
  width: 120px;
  background: url('https://sender.livestrip.com/TabUpload/LogoSmall/live-strip.com-logo.png');
  background-size: contain;
  /* Skaliert das Bild, um es vollständig sichtbar zu machen */
  background-repeat: no-repeat;
  /* Verhindert das Wiederholen des Hintergrundbildes */
  background-position: center;
  /* Zentriert das Bild im Container */

}

.refreshbtn {
  height: 31px !important;
}

.react-draggable .close {
  color: white;
  font-size: 40px;
  margin-right: 10px !important;
  margin-top: -8px !important;
}

.close {
  color: white;
}

.close span {
  color: white;
  font-size: 40px;
}

.pagination ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex: 1;
  display: flex;
  /* Änderung hier */
  flex-wrap: wrap;
  /* Änderung hier */
}

.paginationColumnLeft {
  display: flex;
  justify-content: first;
  margin-bottom: 0px !important;
}

.paginationColumnRight {
  text-align: right;
  margin-bottom: 0px !important;
  flex-flow: row wrap;
  gap: 5px;
  justify-content: right;
}

.imageSelectCheckBox {
  display: none;
}

.videoItem small {
  color: white;
}

.ImageUpload span {
  color: black !important;
}

#MessengerWriteDIV .form-group label {
  color: white;
}

.imageSelectCheckBox:checked {
  display: block !important;
  /* Häkchen anzeigen, wenn das Kontrollkästchen ausgewählt ist */

}

.maxtitle {
  max-width: 70%;
  white-space: nowrap;
  /* Verhindert den Zeilenumbruch */
  overflow: hidden;
  /* Versteckt den überfließenden Inhalt */
  text-overflow: ellipsis;
}

.checkmark {
  display: none;
  position: relative;
  top: 70px;
  left: 80px;
  width: 34px;
  /* Breite und Höhe des Häkchens anpassen */
  height: 34px;

}

.checkmark:after {
  content: "\2713";
  /* ASCII-Code für ein Häkchen */
  font-size: 20px;
  /* Größe des Häkchens */
  color: white;
  /* Farbe des Häkchens */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.paginationColumnRight .btn {
  width: 130px;
  height: 40px !important;
}

.Messengerlist button:first-child {
  margin-left: 5px;
}

.Messengerlist button {
  width: 100px;
  margin-right: 5px;
}


#ImagesListPane small {
  color: white !important;
}


.MessengerWriteDIVbackgroundless {
  background: none !important;
}


@media (max-width: 768px) {
  .paginationColumnLeft {
    display: flex;
    justify-content: center !important;
  }

  .paginationColumnRight {
    text-align: center;
  }
}

.btnMessengerList-conversations {
  margin-left: 21px;
}

.paginationExtraDropdown .paginationdropdownDIV {
  overflow-x: auto !important;
  height: 90vh;
}

.paginationExtraDropdown>.btn-secondary {
  margin-left: 20px;
  height: 38px;
  background-color: white !important;
  color: black !important;
  border: 1px solid #dee2e6 !important;
}

.paginationExtraDropdown>.btn-secondary:hover {
  background-color: white !important;
  color: black !important;
  border: 1px solid #dee2e6 !important;
}

.imageSelectCheckBox {
  margin-left: 3px;
}

.videoSelectCheckBox {
  margin-left: 3px;
}