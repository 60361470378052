.user {
    align-self: flex-start;
    border-radius: 10px;
    width: 80% !important;
    margin-left: 1%;
    margin-bottom: 0.5rem; /* Abstand zwischen den Elementen */
    
  }

  .user:hover {
    background-color: #d4d4d4;
  }

  .user::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 99.5%;
    margin-top: -10px; /* Halbe Höhe des Pfeils, um ihn zentriert zu positionieren */
    border-width: 10px;
    border-style: solid;
    border-color: transparent #ffffff transparent transparent; /* Farben des Pfeils */
   
  }

  .user:hover::before {
    border-color: transparent #d4d4d4 transparent transparent;

  }
  
  .list-groupyou {
    align-items:end;
  }

  .useryou {
    align-self: flex-end;
    border-radius: 10px;
    width: 80% !important;
    margin-bottom: 0.5rem; /* Abstand zwischen den Elementen */
    z-index: 0;
    margin-right: 1%;
    
  }

  .useryou:hover {
    background-color: #d4d4d4;
    z-index: 0 !important;
  }

  .useryou:hover::after {
    border-color: transparent transparent transparent #d4d4d4;

  }

  .useryou::after { /* Verwende ::after statt ::before, um den Pfeil auf der anderen Seite zu platzieren */
    content: "";
    position: absolute;
    top: 50%;
    left: 99.5%; /* Ändere right zu left */
    margin-top: -10px; /* Halbe Höhe des Pfeils, um ihn zentriert zu positionieren */
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #ffffff; /* Ändere die Farben des Pfeils, um ihn auf die andere Seite zu bringen */
    
  }
  