html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lv-player {
  width: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

#app {
  height: 100%;
}

.NavbarDIV{
  text-transform:uppercase;
}
#btnToolbar {
 padding: 4px 0px; 
}

#chatInterfaceMainRow {
  height: calc(100% - 105px);
}

@media (max-height: 467px) or (max-width: 467px) {
  #chatInterfaceMainRow {
    height: calc(100% - 55px);
  }
  #logonavbar {
    min-height: 30px !important;
    width: 100px !important;
  }
  .NavbarDIV {
    height: auto !important;
  }
  .hidemobile {
    display: none !important;
  }
}

@media (max-width: 1200px) {

  #logonavbar {
    min-height: 30px !important;
    width: 100px !important;
  }
  .NavbarDIV {
    height: auto !important;
  }
  .hidemobile {
    display: none !important;
  }
}

.chatInterfaceMainRowChild {
  height: 100%;
}

#chatInterfaceContainer {
  background-color: #000;
  color: #fff;
  height: 100%;
  overflow: hidden;
}

#chatMessageContainer {
  height: calc(100% - 40px);
  background-color: #222;
  overflow-y: scroll;
  color: #fff;
  position: relative;

}

#GuppyChatMessageContainer{
  text-align: center !important;
  height: calc(100% - 40px);
  background-color: #222;
  overflow-y: scroll;
  color: #fff;
  position: relative;
}

#chatMessageContainerNew {
  height: 80%;
  overflow-y: scroll;
  color: #fff;
  position: absolute;
  right: 0;
}

#userListContainer {
  height: 180px;
  background-color: #222;
  color: #fff;
  position: relative;
  overflow-y: scroll;
}

#btnToolbar > .dropdown {
  display: inline-block;
}

#chatVideoContainer {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

#chatVideoContainer > div {
  width: 100%;
  height: 100%;
}

.close {
  z-index: 1000;
  top: 2px;
  right: 2px;
  position: absolute;
  cursor: pointer;
}

.ChatTermin {
  position: relative;
  border-bottom: solid 1px rgba(0,0,0,0.5);
  margin-bottom: 10px;
}
.loadingAnimation {
  animation: blink 0.5s step-start infinite; /* Blinkt jede Sekunde */
}

@keyframes blink {
    0%, 50% {
        opacity: 1; /* Sichtbar */
    }
    51%, 100% {
        opacity: 0.5; /* Unsichtbar */
    }
}

#pauseHintDiv {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color:rgba(255,0,0,0.2);
  color: #fff;
  font-size: 28pt;
  text-align: center;
  padding-top: 45%;
}

.ChatTerminDelete {
  z-index: 1000;
  top: 2px;
  right: 2px;
  position: absolute;
  cursor: pointer;
}

#userListHintsDIV {
  width: 100%;
  font-size: 12px;
  color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 2px 2px;
}

@media (max-height: 467px) or (max-width: 467px) {
  #userListHintsDIV {
    display: none !important;
  }
}

.settingsSidebarDIV {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 15px 10px;
}

@media (max-height: 467px) or (max-width: 467px) {
  #chatVideoContainer > div > .open {
    width: 100% !important;
  }
  .settingsSidebarDIV {
    padding: 5px 5px;
  }
  #statusRow {
    display: none;
  }
}

#streamStatusIndicatorDIV {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: auto !important;
  background-color: transparent;
  padding: 2px 2px;
}

#chatVideoInfo {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 1;
  width: auto !important;
  height: auto !important;
  font-size: 11px;
  color: #fff;
  background-color: rgba(0,0,0,0.5);
  padding: 2px 4px;
}

#chatVideoContainer video {
  object-fit: contain !important;
}

#voyeurInfoDIV {
  position: absolute;
  bottom: 0;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: auto !important;
  text-align: center;
}

#voyeurInfoDIVInner {
  display: inline-block;
  width: auto !important;
  height: auto !important;
  font-size: 14px;
  color: #fff;
  background-color: rgba(0,0,0,0.5);
  padding: 2px 4px;
}

#chatVideoButtons {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 1;
  width: auto !important;
  height: auto !important;
}

#chatMessageButtons {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

#userListButtons {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 1;
}

#mysysmsg {
  height: 50px;
  font-size: 8pt;
  overflow-y: scroll;
  margin-bottom: 4px;
}

#chatStatusContainer {
  height: 50px;
  font-size: 12pt;
  overflow-y: scroll;
  margin-bottom: 4px;
}

#mydebug {
  display: none;
  height: 50px;
  font-size: 8pt;
  overflow-y: scroll;
  margin-bottom: 4px;
}

.list-group {
  margin-bottom: 5px;
}



.cam2camVideo {
  position: absolute;
  display: block;
  background-color: rgb(33,33,33);
  z-index: 2;
  cursor: move;
}

.Cam2CamVideoSoup {
  position: absolute;
  display: block;
  background-color: rgb(33,33,33);
  z-index: 2;
  cursor: move;
}

.cam2camVidLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  color: #fff;
  background-color: rgba(0,0,0,0.6);
  padding: 2px;
  z-index: 3;
  font-size: 12px;
}

.cam2camPollButton {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.cam2camVidLabelButtons {
  font-family: "Segoe MDL2 Assets";
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.userNoteTitle {
  width: 100%;
  position: relative;
}

.userNoteDelete {
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
}

.lv-lv-shelf-0-2-11 {
  color: #fff;
  background-color: rgba(0,0,0,0.9);
}

#autoTextContainer {
  background-color: rgba(0,0,0,0.85);
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
  font-size: 10pt;
  position: absolute;
  left: 0px;
  bottom: 45px;
  width: 100%;
  padding: 3px;
}

.lv-lv-input--select-0-2-7 {
  color: #fff;
}

.lv-lv-input--select-0-2-7 > option {
  color: #000;
  background-color: #fff;
}

.lv-lv-input-0-2-14 {
  color: #fff;
}

.lv-lv-input-0-2-14 > option {
  color: #000;
}

.userListEntry {
  cursor: pointer;
}

.userListEntryInvisible {
  display: none;
}

.lv-icon-encoder {
  background-color: yellow;
}

.lv-lv-input-0-2-16 {
  color: #fff;
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%20200%20200%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M58.2031%2083.2031H141.797L100%20125L58.2031%2083.2031Z%22%20%2F%3E%3C%2Fsvg%3E');
}

.lv-lv-input-0-2-16 > option {
  color: #eee;
  background-color: rgba(0,0,0,0.7);
}

.lv-lv-button-0-2-10 {
  left: initial !important;
  right: 1rem !important;
}

.lv-svg-icon {
  opacity: 1.0 !important;
}

#mycaption {
  position: absolute;
  top: 5px;
  left: 5px;
  color: #fff;
  z-index: 1;
}
#mycaption > h6 {
  margin: 0;
  padding: 0;
}

#localLovenseParent {
  cursor: pointer;
  display: none;
  position: absolute;
  bottom: 4px;
  right: 104px;
  z-index: 2;
  width: 20px;
  height: 100px;
  border: solid 1px lightblue;
  background-color: transparent;
}
#localLovenseBar {
  background-color: cyan;
  bottom: 0px;
  width: 100%;
  position: absolute;
  left: 0px;
}
#localLovenseActive {
  display: none;
  position: absolute;
  bottom: 24px;
  left: 128px;
  z-index: 2;
  width: auto;
  border: solid 1px #aaa;
  background-color: green;
  color: #fff;
  font-size: 8px;
  padding: 2px 4px;
  line-height: 10px;
}
#localLovenseOff {
  display: none;
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  left: 128px;
  z-index: 2;
  width: auto;
  border: solid 1px #aaa;
  background-color: red;
  color: #fff;
  font-size: 8px;
  padding: 2px 4px;
  line-height: 10px;
}
#localDildoParent {
  cursor: pointer;
  display: none;
  position: absolute;
  bottom: 24px;
  right: 4px;
  z-index: 2;
  width: 20px;
  height: 100px;
  border: solid 1px #fff;
  background-color: transparent;
}
#localDildoBar {
  background-color: orangered;
  bottom: 0px;
  width: 100%;
  position: absolute;
  left: 0px;
}
#localDildoActive {
  display: none;
  position: absolute;
  bottom: 44px;
  left: 24px;
  z-index: 2;
  width: auto;
  border: solid 1px #aaa;
  background-color: green;
  color: #fff;
  font-size: 8px;
  padding: 2px 4px;
  line-height: 10px;
}
#localDildoOff {
  display: none;
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 2;
  width: auto;
  border: solid 1px #aaa;
  background-color: red;
  color: #fff;
  font-size: 8px;
  padding: 2px 4px;
  line-height: 10px;
}
#localVolumeParent {
  display: none;
  position: absolute;
  bottom: 4px;
  right: 4px;
  z-index: 2;
  width: 20px;
  height: 100px;
  border: solid 1px #888;
  background-color: transparent;
}

.oncircle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  background-color: lightgreen;
  border: 1px solid rgba(0, 0, 0, 0.5); /* Dünner, halbtransparenter Rand */
  border-radius: 50%; /* Vollständiger Kreis */
}

.NavbarDIV {
  height: 44px;
}

.NavbarDIV li a {
  cursor: pointer;
}

.NavbarDIV li a:hover {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.offcircle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  background-color: red;
  border: 1px solid rgba(0, 0, 0, 0.5); /* Dünner, halbtransparenter Rand */
  border-radius: 50%; /* Vollständiger Kreis */
}
.sleepcircle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  background-color: darkgreen;
}

.pausecircle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  background-color: yellow;
}

.loadingcircle {
  width: 10px;
  height: 10px;
  border-radius: 50%; /* Vollständiger Kreis */
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.5); /* Dünner, halbtransparenter Rand */
  background-color: rgb(6, 53, 209);
}


.meter {
  box-sizing: content-box;
  height: 15px; /* Can be anything */
  margin-top: 5px;
  margin-bottom: 2px;
  display: inline-block;
  position: relative;
  background: #555;
  border-radius: 25px;
  padding: 10px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.orange > span {
  background-image: linear-gradient(#f1a165, #f36d0a);
}

.red > span {
  background-image: linear-gradient(#f0a3a3, #f42323);
}

.nostripes > span > span,
.nostripes > span::after {
  background-image: none;
}

.chat-encodermessage {
  color:rgba(255, 255, 255, 0.3);
}

.userMicAudio {
  position: absolute;
  z-index: 2;
  cursor: move;
  width: 240px;
  height: 30px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(0,0,0,0.6);
}

.userMicAudioLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  color: #fff;
  padding: 2px;
  z-index: 3;
  font-size: 12px;
  background-color: #222;
  color: #eee;
}

.userMicAudioLabelSpan {
  padding-left: 4px;
}

.userMicAudioLabelButtons {
  font-family: "Segoe MDL2 Assets";
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.userMicAudioElement {
  height: 24px;
  width: 160px;
  position: absolute;
  top: 0px;
  left: 120px;
}

.lv-lv-shelf-0-2-17 {
  background-color: rgba(0,0,0,0.5);
}

audio {
  width: 100%;
  height: 15px;
  /*margin-top: 24px;*/
}
/* Für alle Scrollbalken */
::-webkit-scrollbar {
  width: 12px; /* Breite des vertikalen Scrollbalkens */
  height: 12px; /* Höhe des horizontalen Scrollbalkens */
}

::-webkit-scrollbar-track {
  background: #f1f1f16c; /* Hintergrund des Scrollbalkens */
  border-radius: 10px; /* Abgerundete Ecken */
}

::-webkit-scrollbar-thumb {
  background: #f5f5f5; /* Farbe des Scrollgriffes */
  border-radius: 10px; /* Abgerundete Ecken */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Farbe bei Hover */
}



audio::-webkit-media-controls-mute-button {
  color: #fff;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.4);
}

audio::-webkit-media-controls-timeline-container {
  display: none;
}

audio::-webkit-media-controls-panel {
  padding: 0px;
}

audio::-webkit-media-controls-enclosure {
  background: transparent;
}

audio::-webkit-media-controls-current-time-display {
  color: #fff;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.4);
}

audio::-webkit-media-controls-time-remaining-display {
  color: #fff;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.4);
}

audio::-webkit-media-controls-timeline {
  display: none;
}

.localAudioDebug {
  background-color: #222;
  color: #eee;
  font-size: 9pt;
  width: 100%;
  padding: 2px;
  margin-top: 3px;
}

.localVideoDebug {
  background-color: #222;
  color: #eee;
  font-size: 9pt;
  width: 100%;
  padding: 2px;
  margin-top: 3px;
}

.close-button-testoverlay {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.close-button-testoverlay:hover {
  color: red;
  transform: scale(1.1);
}

#ToyTestOverlay {
  border-radius: 30px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 20vh;
  left: 20px;
  padding: 20px;
  text-transform: uppercase;
  opacity: 1;                /* Unsichtbar zu Beginn */
  transform: translateY(-20px); /* Leicht verschoben nach oben */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Weiche Einblendung */
}

#ToyTestOverlay.visible {
  opacity: 1;                /* Sichtbar, wenn `.visible` hinzugefügt wird */
  transform: translateY(0);   /* Bewegt das Element nach unten */
}

#ToyTestOverlay h5 {
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}


#ToyOverlay {
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 35vh;
  left: 20px;
  padding-top: 20px;
  padding-bottom: 20px
}




#ToyOverlay:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.Overlay {
  display: flex;
  color: white;
  z-index: 93;
  padding: 10px;
  cursor: pointer;

}

.OverlayText {
  border-radius: 0px 20px 20px 0px;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  padding-left: 20px;
  padding-right: 30px;
  margin-left: 45px;
  opacity: 0;
  transform: scaleX(0); /* Startet bei 0, vollständig unsichtbar */
  transform-origin: left; /* Skalierung startet von der linken Seite */
  transition: transform 0.4s ease, opacity 0.4s ease; /* Weiche Transition für Skalierung und Sichtbarkeit */
  white-space: nowrap; /* Verhindert Zeilenumbruch */
  z-index: 92;
}

.Overlay:hover .OverlayText {
  opacity: 1;
  transform: scaleX(1); /* Skaliert die Breite auf 100% */
}

.OverlayText h4 {
  position: absolute;
  top: -30px;
  z-index: 92;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}


.OverlayText span {
  position: relative;
  display: inline-block; /* Inline-Block für flexiblen Inhalt */
  padding: 10px;
  margin-left: 5px;
  border-radius: 20px; /* Abgerundete Ecken */
  text-transform: uppercase;
  z-index: 92;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}


.icon:hover {
  cursor: pointer;
}

.OverlayIcon {
  z-index: 93;
  width: auto; /* Breite passt sich automatisch an */
  display: inline-block; /* Verhindert, dass der Container 100% Breite einnimmt */
  width: 35px;
}

.OverlayIcon image {
  width: 300px !important;
}


.OverlayIcon span{
  position: fixed;
  left:30px;
}

.oncircleOverlay{
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 40px;
    display: inline-block;
    background-color: lightgreen;
}
.whisper-bubble {
  background: rgba(142, 68, 173, 0.15);
  border-right: 3px solid #8e44ad;
  padding: 7px 12px;
  border-radius: 8px;
  margin: 6px 7px 0;
  width: fit-content;
  word-wrap: break-word;
  max-width: 80%;
}

.girl-bubble {
  background: rgba(230, 230, 250, 0.15);
  border-right: 3px solid rgba(230, 230, 250, 0.336);
  padding: 7px 12px;
  border-radius: 8px;
  margin: 6px 7px 0;
  width: fit-content;
  word-wrap: break-word;
  align-self: flex-end;
  max-width: 80%;
}

.user-bubble {
  background: rgba(255, 165, 0, 0.1);
  border-left: 3px solid #ff8c00;
  padding: 7px 12px;
  border-radius: 8px;
  margin: 6px 7px 0;
  width: fit-content;
  word-wrap: break-word;
  align-self: flex-start;
  max-width: 80%;
}

.chat-message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; /* Abstand zwischen Nachrichten */
}

.chat-message-container .fromPart {
  font-weight: bold;
  margin-bottom: 3px;
}

.chat-enter{
  display: inline-block;
}
