body, html {
  
  background: url('https://sender.livestrip.com/landing/assets/images/intro/01.jpg') center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat; /* Das Bild soll nicht wiederholt werden */
  background-position: center center; /* Zentriert das Bild */
  background-size: cover; /* Sorgt dafür, dass das Bild immer den gesamten Container bedeckt */
  width: 100%; /* Breite des Elements auf 100% des Elternelements */
  height: 100%; /* Höhe des Elements auf 100% des Elternelements */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#mydebug {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  z-index: 1;
  font-size: 8pt;
}

#mycaption {
  position: absolute;
  top: 5px;
  left: 5px;
  color: #fff;
  z-index: 1;
}
#mycaption > h6 {
  margin: 0;
  padding: 0;
}


#logo {
  position: relative;
  display: flex;
  justify-content: center; /* Zentriert die Kinder horizontal */
  align-items: center; /* Zentriert die Kinder vertikal */
  min-height: 60px;
  width: 235px;
  background: url('https://sender.livestrip.com/TabUpload/LogoSmall/live-strip.com-logo.png');
  background-repeat: no-repeat;
  background-size: cover; 
}

#loginFormDIV {
  border-radius: 10px;

  top: 20%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px; /* Setzt die Breite auf 50% des Elternelements */
  /* Du musst height nicht setzen, wenn es bereits von Flexbox zentriert wird */
  padding: 40px;
  opacity: 0.92;
}

#loginFormDIV .mb-4 {
  display: flex;
  justify-content: center;
}

#loginFormDIV form {
  width: 100%; /* Oder eine feste Breite, je nach Designbedarf */
  max-width: 400px; /* Eine maximale Breite kann helfen, das Formular auf größeren Bildschirmen einzudämmen */
  /* Weitere Stilisierungen hier, falls benötigt */
}


#chatOptionsFormDIV form {
  display:flexbox;
  position: relative;
  height: 100vh;
}

.chatOptionsFormDIVtheReal {
  opacity: 0.95;
  position: relative;
}

#chatOptionsFormDIV .form-control {
  max-width: 400px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-height: 900px) {
  #loginFormDIV {
    top: 5%;
  }
}